































































































import { MembershipStatus, UserResponse } from "@/http/responses/UserResponse";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import EditUserDialog, {
  UserWithMorEmail,
} from "@/components/user/EditUserDialog.vue";
import { tableHeaders } from "@/data/tableHeaders";
import { formatDateLocale } from "@/helpers/date";
import { UpdateUserPayload } from "@/store/actions";
import { UpdateUserHighlightColorTagPayload } from "@/store/actions";

interface UserForm {
  firstName: string;
  lastName: string;
  hguId: string;
  id: string;
  highlightColorTag?: string;
}

@Component({ components: { EditUserDialog } })
export default class Users extends Vue {
  @Action("fetchUsers")
  fetchUsers!: () => Promise<void>;

  @Action("disableUser")
  disableUser!: (payload: string) => Promise<void>;

  @Action("enableUser")
  enableUser!: (payload: string) => Promise<void>;

  @Action("deleteUser")
  deleteUser!: (payload: string) => Promise<void>;

  @Action("updateUser")
  updateUser!: (payload: UpdateUserPayload) => Promise<void>;

  @Action("updateUserHighlightColorTag")
  updateUserHighlightColorTag!: (
    payload: UpdateUserHighlightColorTagPayload
  ) => Promise<void>;

  @Getter("allUsers")
  allUsers!: Array<UserResponse>;

  loading = true;
  headers = tableHeaders;
  memberships = Object.values(MembershipStatus);
  membershipFilter = MembershipStatus.ALL;
  showDialog = false;
  searchKeyword = "";
  editUser: UserForm = {
    firstName: "",
    lastName: "",
    hguId: "",
    id: "",
    highlightColorTag: "",
  };

  get users(): Array<UserResponse> {
    if (this.membershipFilter === MembershipStatus.ALL) return this.allUsers;
    return this.allUsers.filter(
      (user) => user.membership === this.membershipFilter
    );
  }

  formatDate(date: string) {
    return formatDateLocale(new Date(date));
  }

  mounted(): void {
    this.fetchUsers().finally(() => (this.loading = false));
  }

  filterRole(value: string, search: string, item: UserResponse): boolean {
    return value !== null && value === item.membership;
  }

  onEdit(user: UserResponse): void {
    this.editUser = Object.assign({}, user);
    this.showDialog = true;
  }

  onDelete(user: UserResponse): void {
    this.loading = true;
    this.deleteUser(user.id).finally(() => (this.loading = false));
  }

  onEnable(user: UserResponse): void {
    this.loading = true;
    this.enableUser(user.id).finally(() => (this.loading = false));
  }

  onRefresh(user: UserResponse): void {
    this.loading = true;
    this.fetchUsers().finally(() => (this.loading = false));
  }

  onDisable(user: UserResponse): void {
    this.loading = true;
    this.disableUser(user.id).finally(() => (this.loading = false));
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  async saveDialog(payload: { user: UserWithMorEmail }): Promise<void> {
    this.loading = true;
    await this.updateUser({
      id: payload.user.id,
      membership: payload.user.membership,
      hguId: payload.user.hguId,
      morEmail: payload.user.morEmail,
    }).finally(() => {
      this.loading = false;
      this.showDialog = false;
    });
  }

  async saveHighlightColorTag(
    payload: UpdateUserHighlightColorTagPayload
  ): Promise<void> {
    this.loading = true;
    await this.updateUserHighlightColorTag({
      id: payload.id,
      highlightColorTag: payload.highlightColorTag,
    }).finally(() => {
      this.loading = false;
    });
  }
}
