import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Users from "../views/Users.vue";

Vue.use(VueRouter);

console.log(process.env);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Users",
    component: Users,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["idToken"] !== "";
  if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
  if (to.name === "Login" && isAuthenticated) next({ name: "Users" });
  else next();
});

export default router;
