export const tableHeaders = [
  {
    text: "FIRSTNAME",
    align: "start",
    sortable: true,
    value: "firstName",
  },
  { text: "LASTNAME", value: "lastName" },
  { text: "CREATED", value: "created" },
  { text: "BIRTHDAY", value: "birthdate" },
  { text: "MEMBERSHIP", value: "membership" },
  { text: "EMAIL", value: "email" },
  { text: "HGU ID", value: "hguId" },
  { text: "APP ID", value: "id" },
  { text: "EDIT", value: "edit", sortable: false, align: "center" },
  { text: "ACTIVE", value: "toggle", sortable: false, align: "center" },
  { text: "DELETE", value: "delete", sortable: false, align: "center" },
];
