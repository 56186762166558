import { UserResponse } from "@/http/responses/UserResponse";
import Vue from "vue";
import { MutationTree } from "vuex";
import { RootState } from "./state";

export const mutations: MutationTree<RootState> = {
  setIdToken(state, payload: string) {
    state.idToken = payload;
  },
  setUsers(state, payload: Array<UserResponse>) {
    state.users = payload;
  },
  updateUser(state, payload: UserResponse) {
    const user = state.users.find((user) => user.id === payload.id);
    const userIndex = state.users.findIndex((user) => user.id === payload.id);
    Vue.set(state.users, userIndex, {
      ...user,
      firstName: payload.firstName,
      lastName: payload.lastName,
      birthdate: payload.birthdate,
      hguId: payload.hguId,
      membership: payload.membership,
    });
  },
  updateUserHighlightColorTag(state, payload: UserResponse) {
    const user = state.users.find((user) => user.id === payload.id);
    const userIndex = state.users.findIndex((user) => user.id === payload.id);
    Vue.set(state.users, userIndex, {
      ...user,
      highlightColorTag: payload.highlightColorTag,
    });
  },
  enableUser(state, payload: string) {
    const user = state.users.find((user) => user.id === payload);
    const userIndex = state.users.findIndex((user) => user.id === payload);
    Vue.set(state.users, userIndex, { ...user, active: true });
  },
  deleteUser(state, payload: string) {
    const user = state.users.find((user) => user.id === payload);
    const userIndex = state.users.findIndex((user) => user.id === payload);
    state.users.splice(userIndex, 1);
    // Vue.set(state.users, userIndex, { ...user, active: true });
  },
  disableUser(state, payload: string) {
    const user = state.users.find((user) => user.id === payload);
    const userIndex = state.users.findIndex((user) => user.id === payload);
    Vue.set(state.users, userIndex, { ...user, active: false });
  },
  setDrawerState(state, payload: boolean) {
    state.drawerOpen = payload;
  },
};
