import http from "@/http";
import { TokenResponse } from "@/http/responses/TokenResponse";
import { MembershipStatus, UserResponse } from "@/http/responses/UserResponse";
import { ActionTree } from "vuex";
import { RootState } from "./state";

export interface AuthenticatePayload {
  email: string;
  password: string;
}

export interface UpdateUserPayload {
  id: string;
  membership: MembershipStatus;
  hguId?: string;
  morEmail?: string;
}

export interface UpdateUserHighlightColorTagPayload {
  id: string;
  highlightColorTag?: string;
}

export const actions: ActionTree<RootState, RootState> = {
  async authenticate({ commit }, payload: AuthenticatePayload): Promise<void> {
    const response = await http
      .post<TokenResponse>("/auth", payload)
      .then((_) => _.data);

    localStorage.setItem("idToken", response.idToken);
    http.defaults.headers["Authorization"] = `Bearer ${response.idToken}`;

    commit("setIdToken", response.idToken);
  },

  async fetchUsers({ commit }): Promise<void> {
    const response = await http
      .get<Array<UserResponse>>("/users")
      .then((_) => _.data);

    commit("setUsers", response);
  },

  async updateUser({ commit }, payload: UpdateUserPayload): Promise<void> {
    const updatedUser = await http
      .patch<UserResponse>(`/user/${payload.id}`, {
        membership: payload.membership,
        hguId: payload.hguId,
        morEmail: payload.morEmail,
      })
      .then((_) => _.data);

    commit("updateUser", updatedUser);
  },

  async updateUserHighlightColorTag(
    { commit },
    payload: UpdateUserHighlightColorTagPayload
  ): Promise<void> {
    const updatedUser = await http
      .patch<UserResponse>(`/user/${payload.id}/highlight`, {
        highlightColorTag: payload.highlightColorTag,
      })
      .then((_) => _.data);

    commit("updateUserHighlightColorTag", updatedUser);
  },

  async enableUser({ commit }, payload: string): Promise<void> {
    await http.post(`/user/enable/${payload}`).then((_) => _.data);

    commit("enableUser", payload);
  },

  async disableUser({ commit }, payload: string): Promise<void> {
    await http.post(`/user/disable/${payload}`).then((_) => _.data);

    commit("disableUser", payload);
  },

  async deleteUser({ commit }, payload: string): Promise<void> {
    await http.delete(`/user/${payload}`).then((_) => _.data);
    commit("deleteUser", payload);
  },

  toggleDrawer({ commit }, payload: boolean): void {
    commit("setDrawerState", payload);
  },

  reset({ commit }): void {
    localStorage.removeItem("idToken");
    http.defaults.headers["Authorization"] = "";
    commit("setIdToken", "");
    commit("setUsers", []);
  },

  setup({ commit }): boolean {
    const idToken = localStorage.getItem("idToken");
    if (idToken !== null) {
      http.defaults.headers["Authorization"] = `Bearer ${idToken}`;
      commit("setIdToken", idToken);
      return true;
    }
    return false;
  },
};
