






















































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class Sidebar extends Vue {
  @Getter("drawerOpen")
  drawerOpen!: boolean;

  @Action("reset")
  reset!: () => void;

  @Action("toggleDrawer")
  toggleDrawer!: (state: boolean) => void;

  sidebarWidth = 240;
  sidebarMinWidth = 96;
  items = [{ title: "Users", icon: "group", link: "/" }];

  get drawerState(): boolean {
    return this.drawerOpen;
  }

  set drawerState(state: boolean) {
    this.toggleDrawer(state);
  }

  logout(): void {
    this.reset();
    this.$router.push("/login");
  }
}
