var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-1"},[_c('v-card-title',{staticClass:"pa-5 pb-3"},[_c('p',[_vm._v("App Users")]),_c('p',{staticClass:"ml-2"},[_c('v-icon',{on:{"click":function($event){return _vm.onRefresh()}}},[_vm._v(" refresh ")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-select',{staticClass:"mr-md-8",attrs:{"placeholder":"Filter membership","items":_vm.memberships,"clearable":"","single-line":"","hide-details":""},model:{value:(_vm.membershipFilter),callback:function ($$v) {_vm.membershipFilter=$$v},expression:"membershipFilter"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.searchKeyword,"item-key":"name","items-per-page":40,"no-data-text":"Error fetching users","footer-props":{
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
              disableItemsPerPage: true,
            }},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created)))])]}},{key:"item.birthdate",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.birthdate)))])]}},{key:"item.membership",fn:function(ref){
            var item = ref.item;
return [(item.highlightColorTag)?_c('span',{staticClass:"py-1 px-3",staticStyle:{"background-color":"#ffd200","border-radius":"15px"}},[_vm._v(_vm._s(item.membership))]):_c('span',[_vm._v(_vm._s(item.membership))])]}},{key:"item.edit",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" edit ")])]}},{key:"item.toggle",fn:function(ref){
            var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.onDisable(item)}}},[_vm._v(" person ")]):_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.onEnable(item)}}},[_vm._v(" person_outline ")])]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" delete ")])]}}],null,true)})],1)],1)],1)],1),_c('EditUserDialog',{attrs:{"showDialog":_vm.showDialog,"user":_vm.editUser},on:{"close":_vm.closeDialog,"save":_vm.saveDialog,"saveHighlightColorTag":_vm.saveHighlightColorTag}}),_c('v-overlay',{attrs:{"value":_vm.loading,"zIndex":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }