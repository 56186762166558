import {
    format,
    format as formatDate,
    isToday,
    parseISO as parseISODate,
  } from 'date-fns';
  import DeLocale from 'date-fns/locale/de';

export const formatDateLocale = (date: Date) => {
    return formatDate(new Date(date), 'd. MMM. YYY', {locale: DeLocale});
  };