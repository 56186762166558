export enum PrivacySetting {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum MembershipStatus {
  ALL = "ALL",
  GUEST = "GUEST",
  TEMPORARY_MEMBER = "TEMPORARY_MEMBER",
  MEMBER = "MEMBER",
}

export interface UserResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  newEmail?: string;
  phone: string;
  created: Date;
  birthdate: Date;
  profileImage: string;
  job: string;
  degree: string;
  employeer: string;
  residency: string;
  privacy: PrivacySetting;
  showsDetailedProfile?: boolean;
  membership: MembershipStatus;
  memberSince?: Date;
  memberId?: string;
  association?: string;
  associationShort?: string;
  hguId: string;
  active: boolean;
  highlightColorTag?: string;
}
