






































































import { MembershipStatus, UserResponse } from "@/http/responses/UserResponse";
import { Component, Vue } from "vue-property-decorator";

export interface UserWithMorEmail extends UserResponse {
  morEmail?: string;
}

const EditUserDialogProps = Vue.extend({
  props: {
    user: Object as () => UserWithMorEmail,
    showDialog: Boolean,
  },
});

@Component
export default class EditUserDialog extends EditUserDialogProps {
  memberships = Object.values(MembershipStatus).filter(
    (status) => status !== MembershipStatus.ALL
  );

  errors = {
    hguId: "",
    membership: "",
    morEmail: "",
  };

  save(): void {
    if (!this.validate()) return;
    this.$emit("save", {
      user: this.user,
    });
  }

  get show(): boolean {
    return this.showDialog;
  }

  set show(show: boolean) {
    if (!show) {
      this.errors.hguId = "";
      this.errors.membership = "";
      this.errors.morEmail = "";
      this.$emit("close");
    }
  }

  updateHighlightValue(value: boolean) {
    const newValue: string = value ? "#ffd200" : "";
    this.user.highlightColorTag = newValue;

    this.$emit("saveHighlightColorTag", {
      id: this.user.id,
      highlightColorTag: newValue,
    });
  }

  validate(): boolean {
    if (this.user.membership === MembershipStatus.TEMPORARY_MEMBER) {
      this.errors.membership = "Not allowed to set to temporary member";
      return false;
    }
    if (this.user.membership === MembershipStatus.MEMBER && !this.user.hguId) {
      this.errors.hguId = "required";
      return false;
    }
    if (
      this.user.membership === MembershipStatus.MEMBER &&
      !this.user.morEmail
    ) {
      this.errors.morEmail = "required";
      return false;
    }
    if (this.user.membership === MembershipStatus.GUEST && this.user.hguId) {
      this.errors.hguId = "must be empty";
      return false;
    }
    this.errors.hguId = "";
    this.errors.membership = "";
    return true;
  }

  close(): void {
    this.errors.hguId = "";
    this.errors.membership = "";
    this.$emit("close");
  }
}
