














import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Header from "./components/common/Header.vue";
import Sidebar from "./components/common/Sidebar.vue";

@Component({
  components: { Header, Sidebar },
})
export default class LoginView extends Vue {
  @Getter("idToken")
  idToken!: string;
}
