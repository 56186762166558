import router from "@/router";
import store from "@/store";
import axios from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401) return Promise.reject();
    store.dispatch("reset");
    if (router.currentRoute.name === "Login") return Promise.reject();
    router.push("/login");
  }
);

export default http;
