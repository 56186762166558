import { UserResponse } from "@/http/responses/UserResponse";
import { GetterTree } from "vuex";
import { RootState } from "./state";

export const getters: GetterTree<RootState, RootState> = {
  idToken(state): string {
    return state.idToken;
  },
  allUsers(state): Array<UserResponse> {
    return state.users;
  },
  drawerOpen(state): boolean {
    return state.drawerOpen;
  },
};
