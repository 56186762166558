














import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class Header extends Vue {
  @Getter("drawerOpen")
  drawerOpen!: boolean;
  @Action("toggleDrawer")
  toggleDrawer!: (state: boolean) => void;

  toggleSidebar(): void {
    this.toggleDrawer(!this.drawerOpen);
  }
}
