import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { RootState } from "./state";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    idToken: "",
    drawerOpen: true,
    users: [],
  },
  actions,
  mutations,
  getters,
};

export default new Vuex.Store<RootState>(store);
